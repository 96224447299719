


































import { Component, Prop, Vue } from 'vue-property-decorator';
import Teleport from 'vue2-teleport';

type Coords = { x: number; y: number };

@Component({
  name: 'Tooltip',
  components: { Teleport },
})
export default class Tooltip extends Vue {
  @Prop({ default: '' }) text!: string;
  @Prop({ default: 'none' }) maxWidth!: string;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ default: false, type: Boolean }) top!: boolean;
  @Prop({ default: false, type: Boolean }) topCenter!: boolean;
  @Prop({ default: false, type: Boolean }) right!: boolean;
  @Prop({ default: false, type: Boolean }) left!: boolean;
  @Prop({ default: false, type: Boolean }) leftLg!: boolean;
  @Prop({ default: false, type: Boolean }) leftXl!: boolean;
  @Prop({ default: true, type: Boolean }) relative!: boolean;

  value = false;
  coords: Coords = { x: 0, y: 0 };

  changeValue(value: boolean) {
    if (this.disabled) return null;

    this.value = value;
  }

  changeCoords(coords: Coords) {
    if (this.disabled) return null;

    this.coords = coords;
  }

  get env() {
    return process.env;
  }
}
