import {
  IAdFormat,
  IAntiFraudVerdict,
  ISpotOptimizationRule,
  IUserValidatorData,
  PaymentRequestApproveSettingsSigns,
  PaymentRequestApproveSettingsTypes,
  SpotOptimizationRuleCondition,
} from '@/interfaces';

export const MDYS: DateTimeFormat = 'MM/DD/YYYY';
export const DMYS: DateTimeFormat = 'DD/MM/YYYY';

export const YMDH: DateTimeFormat = 'YYYY-MM-DD';
export const YMDHMS: DateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const YMDHMH: DateTimeFormat = 'YYYY-MM-DD-HH:mm';
export const YM: DateTimeFormat = 'YYYY-MM';

export const DMYD: DateTimeFormat = 'DD.MM.YYYY';
export const DMY: DateTimeFormat = 'DD.MM.YY';

export const APPEARANCE_EVENT_REMOVE_ON_NONE = 0;
export const APPEARANCE_EVENT_REMOVE_ON_SCROLL_UP = 1;
export const APPEARANCE_EVENT_REMOVE_ON_SCROLL_DOWN = 2;

export const PAYMENT_REQUEST_MODE_AUTO = 1;
export const PAYMENT_REQUEST_REQUESTED_STATUS = 1 << 1;
export const PAYMENT_REQUEST_PENDING_STATUS = 1 << 2;
export const PAYMENT_REQUEST_APPROVED_STATUS = 1 << 3;
export const PAYMENT_REQUEST_COMPLETED_STATUS = 1 << 4;
export const PAYMENT_REQUEST_REJECTED_STATUS = 1 << 5;
export const PAYMENT_REQUEST_BELONGS_TO_BATCH_STATUS = 1 << 6;

export const DOMAINS_MODERATION_APPROVED_STATUS = 1;
export const DOMAINS_MODERATION_NOT_MODERATED_STATUS = 2;
export const DOMAINS_MODERATION_NOT_APPROVED_STATUS = 3;
export const DOMAINS_MODERATION_IMPOSSIBLE_TO_MODERATE_STATUS = 4;

export const PAYMENT_REQUEST_PRE_APPROVE_STATUS = 1;
export const PAYMENT_REQUEST_PRE_REJECT_STATUS = 2;

export const REVSHARE_MODERATION_PENDING = 2;
export const REVSHARE_MODERATION_APPROVED = 4;
export const REVSHARE_MODERATION_REJECTED = 8;

export const DYNAMIC_REVSHARE_MODERATION_CREATED = 1 << 1;
export const DYNAMIC_REVSHARE_MODERATION_STOPPED = 1 << 2;
export const DYNAMIC_REVSHARE_MODERATION_PENDING = 1 << 3;

export const MODERATION_NOT_MODERATED_STATUS = 1;
export const MODERATION_APPROVED_STATUS = 2;
export const MODERATION_REJECTED_STATUS = 3;

export const VERDICT_ERROR = 0;
export const VERDICT_OK = 1;
export const VERDICT_SUSPECTED = 2;
export const VERDICT_REJECT = 3;
export const VERDICT_NOT_ENOUGH_DATA = 4;

export const FIX_IMPRESSION = 1;
export const FIX_CLICK = 2;
export const FIX_DAY = 3;

export const SPOT_DELETED_STATUS = 1;
export const SPOT_NOT_DELETED_STATUS = 2;
export const SPOT_ALL_STATUS = 3;

export const SPOT_OPTIMIZATION_RULE_APPROVED_STATUS = 1;
export const SPOT_OPTIMIZATION_RULE_NOT_APPROVED_STATUS = 2;
export const SPOT_OPTIMIZATION_RULE_ALL_STATUS = 3;

export const GREATER_THAN_OPERATOR = 'greater_than';
export const LESS_THAN_OPERATOR = 'less_than';
export const EQUAL_OPERATOR = 'equal';
export const DIFF_GREATER_THAN_OPERATOR = 'diff_greater_than';
export const DIFF_LESS_THAN_OPERATOR = 'diff_less_than';
export const DIFF_EQUAL_OPERATOR = 'diff_equal';
export const DIFF_RATIO_GREATER_THAN_OPERATOR = 'diff_ratio_greater_than';
export const DIFF_RATIO_LESS_THAN_OPERATOR = 'diff_ratio_less_than';
export const DIFF_RATIO_EQUAL_OPERATOR = 'diff_ratio_equal';

export const STATS_FILTER_OPERATORS: Record<
  'EQUAL' | 'NOT_EQUAL' | 'LIKE' | 'NOT_LIKE',
  '=' | '!=' | '=@' | '!@'
> = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  LIKE: '=@',
  NOT_LIKE: '!@',
};

export const STATS_FILTER_CONDITIONS: Record<'AND' | 'OR', ';' | ','> = {
  AND: ';',
  OR: ',',
};

export const STATS_FILTER_INSERT_MODES: Record<'SELECT' | 'MANUAL', 'select' | 'manual'> = {
  SELECT: 'select',
  MANUAL: 'manual',
};

export const SPOT_LABELS = {
  optimization_rules: {
    icon: 'mdi-filter-outline',
    text: 'Spot optimization is active',
  },
  money_redirection: {
    icon: 'mdi-link-variant',
    text: 'Spot has a binding',
  },
  ssp: {
    icon: 'mdi-cloud-outline',
    text: 'Spot has a ssp',
  },
  visible_for_publisher: {
    icon: 'mdi-eye-off-outline',
    text: 'Spot is hidden',
  },
};

export const OPERATOR_EXP = {
  [GREATER_THAN_OPERATOR]: '>',
  [LESS_THAN_OPERATOR]: '<',
  [EQUAL_OPERATOR]: '=',
  [DIFF_GREATER_THAN_OPERATOR]: '~>',
  [DIFF_LESS_THAN_OPERATOR]: '~<',
  [DIFF_EQUAL_OPERATOR]: '~=',
  [DIFF_RATIO_GREATER_THAN_OPERATOR]: '%~>',
  [DIFF_RATIO_LESS_THAN_OPERATOR]: '%~<',
  [DIFF_RATIO_EQUAL_OPERATOR]: '%~=',
};

export const AD_FORMAT_COLORS: Record<AdFormat, string> = {
  native: 'blue darken-1',
  push: 'deep-purple lighten-2',
  calendar: 'teal lighten-3',
  inpage_push: 'pink lighten-3',
  popunder: 'purple lighten-3',
  interstitial: 'cyan lighten-3',
  in_stream_ad: 'green lighten-3',
  banner: 'light-green darken-2',
  direct_link: 'purple darken-3',
};

export const AD_FORMAT_COLORS_V2: Record<AdFormat, { color: string; background: string }> = {
  native: {
    color: '#FFFFFF',
    background: '#02A0C3',
  },
  push: {
    color: '#FFFFFF',
    background: '#C084FC',
  },
  calendar: {
    color: '#000000',
    background: '#5EEAD4',
  },
  inpage_push: {
    color: '#FFFFFF',
    background: '#F472B6',
  },
  popunder: {
    color: '#FFFFFF',
    background: '#818CF8',
  },
  interstitial: {
    color: '#000000',
    background: '#22D3EE',
  },
  in_stream_ad: {
    color: '#FFFFFF',
    background: '#02C39A',
  },
  banner: {
    color: '#000000',
    background: '#DAF5F0',
  },
  direct_link: {
    color: '#FFFFFF',
    background: '#FB7185',
  },
};

export const REFRESH_REQUEST_TIMERANGE = 60 * 10; // 10 min

export const TAG_CATEGORY_GROUPS_COLORS: Record<'adult' | 'mainstream', string> = {
  adult: 'red lighten-2',
  mainstream: 'green lighten-2',
};

export const AD_FORMATS: Record<AdFormat | AdFormatSegment, IAdFormat> = {
  native: {
    text: 'native',
    order: 7,
    isSegment: false,
    statsKey: 'Native',
  },
  push: {
    text: 'web-push',
    order: 1,
    isSegment: false,
    statsKey: 'Web Push',
  },
  calendar: {
    text: 'mobile interstitial',
    order: 2,
    isSegment: false,
    statsKey: 'Calendar',
  },
  inpage_push: {
    text: 'inpage',
    order: 0,
    isSegment: false,
    statsKey: 'Inpage Push',
  },
  popunder: {
    text: 'popunder',
    order: 3,
    isSegment: false,
    statsKey: 'Popunder',
  },
  interstitial: {
    text: 'interstitial',
    order: 8,
    isSegment: false,
    statsKey: 'Interstitial',
  },
  in_stream_ad: {
    text: 'video/in-stream-ad',
    order: 4,
    isSegment: false,
    statsKey: 'In-stream ad',
  },
  banner: {
    text: 'banner',
    order: 5,
    isSegment: false,
    statsKey: 'Banner',
  },
  direct_link: {
    text: 'direct link',
    order: 10,
    isSegment: false,
    statsKey: 'Direct Link',
  },
  calendar_interstitial: {
    text: 'calendar interstitial',
    order: 11,
    isSegment: true,
    statsKey: 'Extension',
  },
};

export const NOTIFICATION_SEND_METHODS = [
  { name: 'notification', text: 'Notification' },
  { name: 'mail', text: 'Mail' },
  { name: 'all', text: 'All' },
];

export const ANTIFRAUD_VERDICTS: IAntiFraudVerdict[] = [
  { id: VERDICT_ERROR, text: 'Error', color: '#FFA726', value: 'error' },
  { id: VERDICT_OK, text: 'OK', color: '#4CAF50', value: 'ok' },
  { id: VERDICT_SUSPECTED, text: 'Suspected', color: '#FFC107', value: 'suspected' },
  { id: VERDICT_REJECT, text: 'Reject', color: '#F44336', value: 'reject' },
  {
    id: VERDICT_NOT_ENOUGH_DATA,
    text: 'Not enough data',
    color: '#9E9E9E',
    value: 'not_enough_data',
  },
];

export const OPTIMIZATION_RULE_CONDITIONS: {
  key: SpotOptimizationRuleCondition;
  text: string;
}[] = [
  { key: 'tz_offset', text: 'Timezone offset' },
  { key: 'ge_ip', text: 'GE IP' },
  { key: 'dch_ip', text: 'DCH IP' },
  { key: 'country', text: 'Country' },
  { key: 'region', text: 'Region' },
  { key: 'timerange', text: 'Timerange' },
  { key: 'primary_referrer', text: 'Primary refferer' },
  { key: 'in_targets', text: 'In Targets' },
  { key: 'keyword', text: 'Keyword' },
];

export const OPTIMIZATION_RULE_DISPLAY_MECHANICS: {
  key: ISpotOptimizationRule['frontend_apply']['display_mechanics'];
  text: string;
}[] = [
  { key: 'default', text: 'Default' },
  { key: 'display_hidden', text: 'Display hidden' },
];

export const AD_FORMAT_GROUPS = [
  {
    native: 'n',
    push: 'wp',
    inpage_push: 'ip',
    calendar: 'i',
  },
  {
    popunder: 'p',
    in_stream_ad: 'v',
    banner: 'b',
    interstitial: 'in',
  },
];

export const PAYMENT_REQUEST_APPROVE_SETTINGS_TYPES: PaymentRequestApproveSettingsTypes = {
  ABSOLUTE: 1,
  RELATIVE: 0,
};

export const PAYMENT_REQUEST_APPROVE_SETTINGS_SIGNS: PaymentRequestApproveSettingsSigns = {
  LESS_THAN: '<',
  GREATER_THAN: '>',
};

export const GRAYSCALE_PALETTE_MIDPOINT = 186;

export const EMAIL_VALIDATOR_STATUSES: Record<
  IUserValidatorData['is_actual'],
  { color: string }
> = {
  1: {
    color: 'red',
  },
  2: {
    color: 'yellow',
  },
  3: {
    color: 'green',
  },
};

export const ZERO_CLOSE_RATIO_INFO =
  'The value of the close ratio is set to 0%, but the logic of operation will be the same as with the value 50%';

export const FINISHED_ONBOARDING_CREATE_AD = 'FINISHED_ONBOARDING_CREATE_AD';
export const FINISHED_NOTICE_INSTALLING_CODE = 'FINISHED_NOTICE_INSTALLING_CODE';
export const FINISHED_ONBOARDING_COPY_CODE = 'FINISHED_ONBOARDING_COPY_CODE';
export const FINISHED_ONBOARDING_CREATE_AD_MODAL = 'FINISHED_ONBOARDING_CREATE_AD_MODAL';

export const FINISHED_HOW_WORK_AD_CODES = 'FINISHED_HOW_WORK_AD_CODES';
