var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-inline-block",class:[].concat( (_vm.relative ? ['tw-relative'] : []) )},[_c('div',{on:{"mouseover":function($event){return _vm.changeValue(true)},"mouseleave":function($event){return _vm.changeValue(false)}}},[_vm._t("default")],2),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],staticClass:"tw-absolute tw-z-50 tw-flex tw-w-max tw-px-2 tw-py-1 tw-bg-mybid-secondary",class:{
        'tw-bottom-full tw-transform tw--translate-x-1/2': _vm.topCenter,
        'tw-bottom-12 tw-left-0': _vm.top,
        'tw-bottom-0 tw-left-6': _vm.right,
        'tw-bottom-0 tw-right-3': _vm.left,
        'tw--bottom-12 tw--left-44': _vm.leftLg,
        'tw--bottom-12 tw--left-52': _vm.leftXl,
        'tw-rounded-md': ['Clickadilla', 'Onclicka'].includes(_vm.env.VUE_APP_ORG_NAME),
      }},[_vm._t("content",[_c('span',{staticClass:"tw-text-xs tw-font-gotham tw-font-normal tw-text-white",class:{
            'dark:tw-text-mybid-dark': _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
          }},[_vm._t("text",[_vm._v(_vm._s(_vm.text))])],2)])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }